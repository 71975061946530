import { render, staticRenderFns } from "./ListaDeCompras.vue?vue&type=template&id=b8026634&scoped=true"
import script from "./ListaDeCompras.vue?vue&type=script&lang=js"
export * from "./ListaDeCompras.vue?vue&type=script&lang=js"
import style0 from "./ListaDeCompras.vue?vue&type=style&index=0&id=b8026634&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8026634",
  null
  
)

export default component.exports